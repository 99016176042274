import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3825bdc1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "my-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        variant: "elevated",
        style: {"padding":"20px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { justify: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_responsive, {
                    class: "mx-auto mb-10",
                    "max-width": "344"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: $setup.email,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
                        "hide-details": "auto",
                        label: _ctx.$t('forgotPasswordView.emailLabel'),
                        placeholder: "johndoe@gmail.com",
                        type: "email"
                      }, null, 8, ["modelValue", "label"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.$t("forgotPasswordView.sendResetLinkText")), 1),
                    _createVNode(_component_v_btn, {
                      onClick: $setup.forgotPassword,
                      class: "mx-auto",
                      style: {"width":"344px"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("forgotPasswordView.sendButtonText")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}