<template>
  <div>
    <h1>Зачем учить три формы слова?</h1>
    <br />
    <p>
      Часто говорят, что эстонский очень сложный язык, поскольку здесь 14
      падежей. На самом деле все не так плохо. У падежей своя логика, освоив
      которую вы сможете легко составлять пазл эстонского языка. А основой этого
      пазла являются первые три формы существительного (или первые три падежа).
    </p>
    <br />
    <p>Давайте возьмем для примера два слова: sõber (друг) и raamat (книга).</p>
    <img
      :src="require('@/assets/rulesImg/intro1.png')"
      class="clickable-image"
      @click="openModal(require('@/assets/rulesImg/intro1.png'))"
    />
    <br />
    <p>
      У каждого падежа есть свои вопросы. Зачем вам их знать? Они помогут вам
      при выборе падежа. Когда вы будете учить глаголы, то следует обращать
      внимания на то, с какими вопросами они работают. Зная вопрос глагола вы
      сможете выбрать верную форму существительного или прилагательного.
      Например: tahtma, tahta, tahan (хотеть) работает с вопросом Keda? Mida?
    </p>
    <br />
    <p>
      Посмотрите в таблице, какой падеж работает с вопросом Keda? Mida?
      Правильно! Osastav или третий падеж. Значит, если вам нужно сказать "Я
      хочу эту книгу.", то слова "эту" и "книгу" вы возьмете в третьем падеже:
      Ma tahan seda raamatut.
    </p>
    <br />
    <p>Перед вами базовый пример того, как работают три формы.</p>
    <img
      :src="require('@/assets/rulesImg/intro3.png')"
      class="clickable-image"
      @click="openModal(require('@/assets/rulesImg/intro3.png'))"
    />
    <br />
    <p>
      Если вы только начали изучать эстонский, то вам следует запомнить просто
      то, что:
    </p>
    <ol>
      <li>
        первую форму мы используем для слов, которые отвечают на вопросы Кто?
        Что? - See on raamat. (Это книга.); Menüü on luau peal. (Меню на столе.)
      </li>
      <li>
        Вторую форму мы используем, если хотим выразить принадлежность - see on
        sõbra auto (это машина друга), õpetaja raamat (книга учителя), kohviku
        lahtiolekuajad (часы работы кафе).
      </li>
      <li>
        Третью форму используем с числительными 2 и более - kolm kassi (три
        кота), viiskümmend külalist (50 гостей), kolm linna (три города).
      </li>
    </ol>
    <br />
    <p>
      В данной таблице приведена общая логика использования первых трех падежей
      (или первых трех форм, как их часто называют). Вы сможете прочитать про
      каждый падеж отдельно, выбрав его в меню
    </p>
    <br />
    <img
      :src="require('@/assets/rulesImg/intro2.png')"
      class="clickable-image"
      @click="openModal(require('@/assets/rulesImg/intro2.png'))"
    />
  </div>

  <!--  Dialog for larger image view-->
  <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <button class="close-btn" @click="closeModal">X</button>
      <img :src="modalImage" class="modal-image" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const isModalOpen = ref(false);
const modalImage = ref("");

function openModal(imageSrc: string) {
  modalImage.value = imageSrc;
  isModalOpen.value = true;
}

function closeModal() {
  isModalOpen.value = false;
  modalImage.value = "";
}
</script>

<style scoped>
p,
ol,
li {
  text-align: left;
}

li {
  margin-left: 28px;
  padding-left: 4px;
  margin-bottom: 4px;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.clickable-image {
  cursor: pointer;
  max-width: 95%;
  height: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  overflow: auto;
}

.modal-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
