<template>
  <v-tabs v-model="activeTab" align-tabs="center">
    <v-tab value="kasutajad">Kasutajad</v-tab>
    <v-tab value="materjalid">Materjalid</v-tab>
    <v-tab value="statistika">Statistika</v-tab>
  </v-tabs>

  <v-window v-model="activeTab">
    <DashboardUsers></DashboardUsers>
    <DashboardCourses></DashboardCourses>
    <DashboardStats></DashboardStats>
  </v-window>
</template>

<script lang="ts">
import { ref } from "vue";

import DashboardUsers from "@/components/DashboardUsers.vue";
import DashboardCourses from "@/components/DashboardCourses.vue";
import DashboardStats from "@/components/DashboardStats.vue";

export default {
  components: { DashboardStats, DashboardCourses, DashboardUsers },
  setup() {
    const activeTab = ref("kasutajad");

    return {
      activeTab,
    };
  },
};
</script>
