<template>
  <v-row class="d-flex justify-space-between">
    <v-col cols="6">
      <v-card-text class="d-flex justify-left" style="font-weight: bold">
        {{ topic.name }}</v-card-text
      >
    </v-col>
    <v-col cols="5">
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="text-none pa-1 word-progress"
          variant="outlined"
          :style="{ '--progress-width': wordProgress }"
          @click="navigateToWords"
          :disabled="
            !(topic.availableInFree || userTier === 'PAID') || !userEnrollment
          "
          >{{ $t("topicItem.words") }} {{ topic.completedWords }}/{{
            topic.wordCount
          }}
        </v-btn>
        <v-btn
          class="text-none pa-1 sentence-progress"
          variant="outlined"
          :style="{ '--progress-width': sentenceProgress }"
          @click="navigateToSentences"
          :disabled="
            !(topic.availableInFree || userTier === 'PAID') || !userEnrollment
          "
          >{{ $t("topicItem.sentences") }} {{ topic.completedSentences }}/{{
            topic.wordCount
          }}</v-btn
        >
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
import { useCourseStore } from "@/store/courseStore";
import { useRouter } from "vue-router";

export default {
  name: "TopicItem",
  props: {
    topic: Object,
    userTier: String,
  },
  computed: {
    wordProgress() {
      const progressWords = Math.min(
        (this.topic.completedWords / this.topic.wordCount) * 100,
        100,
      );
      return progressWords + "%";
    },
    sentenceProgress() {
      const progressSentence = Math.min(
        (this.topic.completedSentences / this.topic.wordCount) * 100,
        100,
      );
      return progressSentence + "%";
    },
  },
  setup(props) {
    const courseStore = useCourseStore();
    const router = useRouter();
    const userEnrollment = courseStore.currentCourse?.userEnrollment;

    const navigateToWords = () => {
      // console.log("Topic Item Clicked: ", JSON.stringify(props.topic.name));

      courseStore.setTopic(props.topic);

      // console.log("Stored Topic Id: ", JSON.stringify(props.topic.id));

      router.push("/words");
    };

    const navigateToSentences = () => {
      // console.log("Topic Item Clicked: ", JSON.stringify(props.topic));

      courseStore.setTopic(props.topic);

      // console.log("Stored Topic Id: ", JSON.stringify(props.topic.id));

      router.push("/sentences");
    };

    return { navigateToWords, navigateToSentences, userEnrollment };
  },
};
</script>

<style>
.word-progress::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(220, 222, 251, 0.6);
  width: var(--progress-width);
}

.sentence-progress::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(220, 222, 251, 0.6); /* Progress color */
  width: var(--progress-width); /* Adjust width for progress */
}
</style>
