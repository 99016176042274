<template>
  <v-container class="my-container">
    <div class="terms-container">
      <v-card variant="elevated" style="padding: 20px" class="mb-5">
        <section class="terms-section">
          <h3>{{ $t("termsAndConditions.termsOfUse") }}</h3>
          <p>
            {{ $t("termsAndConditions.subscriptionInfo") }}
          </p>
          <br />
          <p>
            {{ $t("termsAndConditions.cancelSubscriptionInfo") }}
          </p>
        </section>
      </v-card>
      <v-card variant="elevated" style="padding: 20px">
        <section class="terms-section">
          <h3>{{ $t("termsAndConditions.privacyPolicy") }}</h3>
          <p>
            {{ $t("termsAndConditions.personalDataInfo") }}
          </p>
          <br />

          <h3>{{ $t("termsAndConditions.dataCollection") }}</h3>
          <p>{{ $t("termsAndConditions.dataCollectionInfo") }}</p>
          <ul>
            <li>
              {{ $t("termsAndConditions.personalInformation") }}
            </li>
            <li>
              {{ $t("termsAndConditions.appUsageInfo") }}
            </li>
          </ul>
          <br />

          <h3>{{ $t("termsAndConditions.dataUsage") }}</h3>
          <p>{{ $t("termsAndConditions.dataUsageInfo") }}</p>
          <ul>
            <li>{{ $t("termsAndConditions.appImprovement") }}</li>
            <li>{{ $t("termsAndConditions.userSupport") }}</li>
            <li>{{ $t("termsAndConditions.updatesAndNotifications") }}</li>
          </ul>
          <br />

          <h3>{{ $t("termsAndConditions.dataProtection") }}</h3>
          <p>
            {{ $t("termsAndConditions.dataProtectionInfo") }}
          </p>
          <br />

          <h3>{{ $t("termsAndConditions.userRights") }}</h3>
          <p>
            {{ $t("termsAndConditions.gdprRightsInfo") }}
          </p>
          <ul>
            <li>{{ $t("termsAndConditions.accessRight") }}</li>
            <li>{{ $t("termsAndConditions.rectificationRight") }}</li>
            <li>{{ $t("termsAndConditions.erasureRight") }}</li>
            <li>{{ $t("termsAndConditions.restrictionRight") }}</li>
            <li>{{ $t("termsAndConditions.dataPortabilityRight") }}</li>
            <li>{{ $t("termsAndConditions.objectionRight") }}</li>
          </ul>
          <p>
            {{ $t("termsAndConditions.contactInfo") }}
          </p>
          <br />

          <h3>{{ $t("termsAndConditions.privacyPolicyChanges") }}</h3>
          <p>
            {{ $t("termsAndConditions.privacyPolicyChangesInfo") }}
          </p>
        </section>
      </v-card>
    </div>
  </v-container>
</template>

<script setup lang="ts"></script>

<style scoped>
.my-container {
  max-width: 1200px;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some padding for visual separation */
}

.terms-container {
  text-align: left;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.terms-section {
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  line-height: 1.6;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}
</style>

<!--<script lang="ts">-->
<!--import {defineComponent} from 'vue'-->

<!--export default defineComponent({-->
<!--  name: "TermsAndConditions"-->
<!--})-->
<!--</script>-->

<!--<template>-->

<!--</template>-->

<!--<style scoped>-->

<!--</style>-->
