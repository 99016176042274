<template>
  <div class="outer-container">
    <h4>{{ $t("dailyMilestoneBar.heading") }}</h4>
    <div class="progress-container">
      <div class="progress-wrapper">
        <v-progress-linear
          color="yellow-accent-4"
          height="14"
          :model-value="progressPercentage"
          striped
        >
          <template v-slot:default>
            <strong>{{ userStore.currentProgress || 0 }}</strong>
          </template>
        </v-progress-linear>
      </div>
      <v-icon v-if="userStore.currentProgress < 5" class="start-icon"
        >mdi-star</v-icon
      >
      <v-icon v-else class="end-icon">mdi-star</v-icon>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted } from "vue";
import { useUserStore } from "@/store/userStore";
import axios from "axios";

const userStore = useUserStore();
const maxProgress = 5;

const progressPercentage = computed(() => {
  return maxProgress > 0 ? (userStore.currentProgress / maxProgress) * 100 : 0;
});

async function initializeDailyBaseline() {
  const response = await axios.get("/api/course/list");

  const totalCompletedWords = response.data.reduce(
    (sum, course) => sum + (course.userEnrollment?.completedWords || 0),
    0,
  );
  const totalCompletedSentences = response.data.reduce(
    (sum, course) => sum + (course.userEnrollment?.completedSentences || 0),
    0,
  );
  const startOfDayBaseline = totalCompletedWords + totalCompletedSentences;

  userStore.setDailyBaseline(startOfDayBaseline);
}

async function updateDailyProgress() {
  const response = await axios.get("/api/course/list");

  const totalCompletedWords = response.data.reduce(
    (sum, course) => sum + (course.userEnrollment?.completedWords || 0),
    0,
  );
  const totalCompletedSentences = response.data.reduce(
    (sum, course) => sum + (course.userEnrollment?.completedSentences || 0),
    0,
  );
  const currentTotalCompleted = totalCompletedWords + totalCompletedSentences;

  // Update progress only if total has increased beyond the baseline
  if (currentTotalCompleted > userStore.dailyBaseline) {
    userStore.updateProgress(currentTotalCompleted);
  }
}

onMounted(async () => {
  const isNewDay = userStore.checkForNewDay();

  if (isNewDay) {
    await initializeDailyBaseline();
  } else {
    await updateDailyProgress();
  }

  window.addEventListener("update-progress", updateDailyProgress);
});

onUnmounted(() => {
  window.removeEventListener("update-progress", updateDailyProgress);
});
</script>

<style scoped>
.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-container {
  display: flex;
  align-items: center;
  width: 350px;
}

.progress-wrapper {
  flex-grow: 1;
  margin: 0 8px;
}

.start-icon,
.end-icon {
  font-size: 24px;
  flex-shrink: 0;
}

.start-icon {
  color: lightgrey;
}

.end-icon {
  color: gold;
  font-size: 36px;
}

.half-icon {
  color: silver;
}

.v-progress-linear {
  width: 100%;
  background-color: white;
}

strong {
  font-size: 13px;
}
</style>
