<template>
  <v-container class="my-container">
    <v-responsive>
      <!-- Mobile Navigation -->
      <div v-if="!isLargeScreen" class="mobile-nav">
        <div class="dropdown-container">
          <v-btn
            @click="toggleOverlay"
            ref="dropdownBtn"
            class="dropdown-trigger"
            variant="flat"
            color="#dcdefb"
            :aria-expanded="overlayVisible"
          >
            <v-icon>mdi-chevron-double-down</v-icon>
          </v-btn>
          <v-overlay
            v-model="overlayVisible"
            location-strategy="connected"
            location="bottom"
            :scrim="true"
            :attach="'.dropdown-trigger'"
            scroll-strategy="none"
            class="overlay-wrapper"
          >
            <v-card class="dropdown-overlay">
              <v-list>
                <v-list-item
                  v-for="(heading, index) in tabHeadings"
                  :key="index"
                  @click.stop="selectRule(index)"
                  class="dropdown-item"
                  :active="tab === index"
                  variant="flat"
                >
                  <v-list-item-title>{{ heading }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-overlay>
        </div>
      </div>

      <v-card class="main-content">
        <div class="d-flex flex-row">
          <!-- Desktop Navigation (Tabs) -->
          <v-tabs
            v-if="isLargeScreen"
            v-model="tab"
            direction="vertical"
            class="tabs-container"
          >
            <v-tab
              v-for="(heading, index) in tabHeadings"
              :key="index"
              :value="index"
            >
              {{ heading }}
            </v-tab>
          </v-tabs>

          <!-- Content Area -->
          <v-tabs-window v-model="tab" class="content-area">
            <v-tabs-window-item
              v-for="(rule, index) in rules"
              :key="index"
              :value="index"
            >
              <v-card flat>
                <v-card-text>
                  <component :is="rule.component" />
                </v-card-text>
              </v-card>
            </v-tabs-window-item>
          </v-tabs-window>
        </div>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script setup lang="ts">
import { createApp, nextTick, onMounted, onUnmounted, ref } from "vue";
import CasesIntroduction from "@/components/rules/CasesIntroduction.vue";
import Rule1Nimetav from "@/components/rules/Rule1Nimetav.vue";
import Rule2Omastavtav from "@/components/rules/Rule2Omastav.vue";
import Rule3Osastav from "@/components/rules/Rule3Osastav.vue";

const rules = [
  { component: CasesIntroduction },
  { component: Rule1Nimetav },
  { component: Rule2Omastavtav },
  { component: Rule3Osastav },
];
const tab = ref(0);
const tabHeadings = ref<string[]>([]);
const overlayVisible = ref(false);
const isLargeScreen = ref(window.innerWidth >= 1200);

function updateScreenSize() {
  isLargeScreen.value = window.innerWidth >= 1200;
}

// Extract <h1> content from each component
async function getHeadings() {
  tabHeadings.value = [];

  for (const rule of rules) {
    const wrapper = document.createElement("div");
    const app = createApp(rule.component);
    app.mount(wrapper);

    // Wait for rendering and extract the <h1> content
    await nextTick(); // Ensure DOM updates
    const heading =
      wrapper.querySelector("h1")?.innerText?.trim() || "Untitled Rule";
    tabHeadings.value.push(heading);

    // Clean up
    app.unmount();
    wrapper.remove();
  }
}

function toggleOverlay() {
  overlayVisible.value = !overlayVisible.value;
}

function selectRule(index: number) {
  tab.value = index; //Update the active tab
  overlayVisible.value = false; // Close the overlay
}

onMounted(() => {
  getHeadings();
  if (typeof window !== "undefined") {
    window.addEventListener("resize", updateScreenSize);
  }
});

onUnmounted(() => {
  if (typeof window !== "undefined") {
    window.removeEventListener("resize", updateScreenSize);
  }
});
</script>

<style scoped>
.my-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.v-card {
  padding: 4px;
}

.v-card-text {
  font-size: 1rem;
}

.content-area {
  width: 100%;
  padding: 20px;
}

.tabs-container {
  max-width: 200px; /* Default for large screens */
  border-right: 1px solid #ddd;
  transition: max-width 0.3s ease-in-out;
}

.tabs-container .v-btn.v-tab--selected {
  background-color: #dcdefb !important;
  color: white !important;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.mobile-nav {
  position: absolute;
  top: 10px;
  z-index: 100;
}

.dropdown-container {
  margin-left: 10px;
  position: relative;
}

.dropdown-trigger {
  cursor: pointer;
}

.dropdown-overlay {
  width: 200px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

:deep(.dropdown-item) {
  transition: background-color 0.2s;
  border-radius: 4px;
  margin: 2px 0;
}

:deep(.dropdown-item:hover) {
  background-color: #dcdefb !important;
}

:deep(.dropdown-item.v-list-item--active) {
  background-color: #dcdefb !important;
  color: white !important;
}

.v-list-item-title {
  font-size: 1rem;
  padding: 5px 0;
  white-space: normal;
  text-align: left;
}

/* Allow text wrapping and remove uppercase in tab content */
:deep(.v-btn__content) {
  white-space: normal !important;
  text-transform: none !important;
  text-align: left;
}

.overlay-wrapper {
  position: relative;
}
</style>
