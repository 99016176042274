<template>
  <v-window-item value="statistika">
    SIIA TULEB INFO AKTIIVSETE KASUTAJATE KOHTA KUU/KVARTALI/AASTA LÕIKES & UUED
    KASUTAJAD & ÕPPIJATE ARV KURSUSTE JA TEEMADE KAUPA
  </v-window-item>
</template>

<script setup lang="ts"></script>

<style scoped></style>
