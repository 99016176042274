<template>
  <div>
    <v-btn-toggle v-model="currentLang" class="custom-row" justify="center">
      <v-btn
        :class="{ 'active-btn': currentLang === 'RU' }"
        value="RU"
        class="toggle-btn"
        @click="updateLanguage('RU')"
      >
        <v-img src="@/assets/flags/ru.png" class="flag-img"></v-img>
        <span class="flag-label">Русский</span>
      </v-btn>
      <v-btn
        :class="{ 'active-btn': currentLang === 'EN' }"
        value="EN"
        class="toggle-btn"
        @click="updateLanguage('EN')"
      >
        <v-img src="@/assets/flags/gb.png" class="flag-img"></v-img>
        <span class="flag-label">English</span>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useUserStore } from "@/store/userStore";

// Define emits for this component
const emit = defineEmits(["updateLanguage"]);

const store = useUserStore();
const currentLang = ref(store.selectedStudyLang);

watch(currentLang, (newLang) => {
  store.setSelectedStudyLang(newLang);
});

function updateLanguage(lang: string) {
  currentLang.value = lang;
  emit("updateLanguage");
}
</script>

<style scoped>
.custom-row {
  margin-top: 1rem; /* Example margin to avoid overlapping */
}

.toggle-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 5px;
  cursor: pointer; /* Ensure the pointer cursor */
  background-color: white; /* default background color */
}

.flag-img {
  width: 32px;
  height: auto;
}

.flag-label {
  margin-top: 4px;
  font-size: 12px;
}

/* Add custom style for the selected state */
.active-btn {
  background-color: #dfcddf !important;
  color: white !important; /* Ensure text color is readable */
}
</style>
