<template>
  <v-container class="my-container">
    <v-card variant="elevated" style="padding: 20px">
      <v-row justify="center">
        <v-col>
          <v-responsive class="mx-auto mb-10" max-width="544">
            <p class="mb-2">
              {{ $t("resetPasswordView.passwordInstructions") }}
            </p>
            <v-text-field
              v-model="password"
              :label="$t('resetPasswordView.password')"
              type="password"
            ></v-text-field>
            <div v-if="password.length || confirmPassword.length">
              <div
                :class="{
                  'text-success': rules.uppercase,
                  'font-weight-bold': rules.uppercase,
                  'text-uppercase': rules.uppercase,
                }"
              >
                {{ $t("resetPasswordView.uppercase") }}
              </div>
              <div
                :class="{
                  'text-success': rules.lowercase,
                  'font-weight-bold': rules.lowercase,
                  'text-uppercase': rules.lowercase,
                }"
              >
                {{ $t("resetPasswordView.lowercase") }}
              </div>
              <div
                :class="{
                  'text-success': rules.numeric,
                  'font-weight-bold': rules.numeric,
                  'text-uppercase': rules.numeric,
                }"
              >
                {{ $t("resetPasswordView.numeric") }}
              </div>
              <div
                :class="{
                  'text-success': rules.length,
                  'font-weight-bold': rules.length,
                  'text-uppercase': rules.length,
                }"
              >
                {{ $t("resetPasswordView.length") }}
              </div>
              <div
                :class="{
                  'text-success': rules.match,
                  'font-weight-bold': rules.match,
                  'text-uppercase': rules.match,
                }"
              >
                {{ $t("resetPasswordView.passwordMatch") }}
              </div>
            </div>
            <v-text-field
              v-model="confirmPassword"
              :label="$t('resetPasswordView.confirmPassword')"
              type="password"
              class="mt-3"
            ></v-text-field>
          </v-responsive>

          <div class="d-flex flex-column align-center">
            <b>{{ $t("resetPasswordView.confirmNewPassword") }}</b>
            <v-btn
              @click="resetPassword"
              class="mx-auto"
              style="width: 344px"
              >{{ $t("resetPasswordView.confirmButton") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { onMounted, reactive, ref, watch } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import router from "@/router";

export default {
  setup() {
    const password = ref("");
    const confirmPassword = ref("");
    const route = useRoute();

    const rules = reactive({
      uppercase: false,
      lowercase: false,
      numeric: false,
      length: false,
      match: false,
    });

    watch([password, confirmPassword], () => {
      rules.uppercase = /[A-Z]/.test(password.value);
      rules.lowercase = /[a-z]/.test(password.value);
      rules.numeric = /[0-9]/.test(password.value);
      rules.length = password.value.length >= 8;
      rules.match =
        password.value && confirmPassword.value
          ? password.value === confirmPassword.value
          : false;
    });

    let resetToken = "";
    onMounted(() => {
      if (route.query.token) {
        resetToken = route.query.token as string;
      }
    });

    const resetPassword = async () => {
      if (password.value !== confirmPassword.value) {
        console.error("Passwords do not match");
        return;
      }

      try {
        await axios.post("/api/auth/reset-password", {
          password: password.value,
          token: resetToken,
        });
        password.value = "";
        confirmPassword.value = "";
        router.push("/");
      } catch (error) {
        console.error("Salasõna lähtestamine ebaõnnestus", error);
      }
    };

    return { password, confirmPassword, resetPassword, rules };
  },
};
</script>

<style scoped>
.my-container {
  max-width: 800px;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some padding for visual separation */
}
</style>
