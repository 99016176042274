import { defineStore } from "pinia";

interface Course {
  createdAt: string;
  objectStatus: string;
  id: string;
  name: string;
  description: string;
  type: string;
  userEnrollment: string | null;
}

interface Topic {
  courseId: string;
  createdAt: string;
  description: string | null;
  id: string;
  name: string;
  objectStatus: string;
}

interface CourseState {
  currentCourse: Course | null;
  currentTopic: Topic | null;
}

export const useCourseStore = defineStore("course", {
  state: (): CourseState => ({
    currentCourse: JSON.parse(localStorage.getItem("currentCourse") || "null"),
    currentTopic: JSON.parse(localStorage.getItem("currentTopic") || "null"),
  }),
  actions: {
    setCourse(course: Course | null) {
      this.currentCourse = course;
      localStorage.setItem("currentCourse", JSON.stringify(course));
    },
    setTopic(topic: Topic | null) {
      this.currentTopic = topic;
      localStorage.setItem("currentTopic", JSON.stringify(topic));
    },
    removeCourse() {
      this.currentCourse = null;
      localStorage.removeItem("currentCourse");
    },
    removeTopic() {
      this.currentTopic = null;
      localStorage.removeItem("currentTopic");
    },
  },
});
