<template>
  <v-window-item value="kasutajad">
    <v-row
      class="flex-row justify-center align-center py-4"
      style="height: 87px"
    >
      <v-btn @click="toggleId" class="me-6" height="100%" variant="outlined">{{
        toggle ? "Hide Delicate" : "Display Delicate"
      }}</v-btn>
      <v-text-field
        v-model="searchTerm"
        type="text"
        placeholder="Search user"
        style="max-width: 300px; height: 100%"
      />
    </v-row>

    <v-data-table :headers="displayHeaders" :items="filteredUsers">
      <template v-slot:item="{ item }">
        <tr>
          <td v-for="header in displayHeaders" :key="header.key">
            <span v-if="header.key === 'role' && toggle">
              <span :class="{ 'font-weight-bold': item.role === 'ADMIN' }">{{
                item.role
              }}</span>
              <v-btn
                size="small"
                :color="
                  item.role === 'USER' ? 'grey-lighten-2' : 'pink-lighten-2'
                "
                @click="updateUserRole(item)"
              >
                Update Role
              </v-btn>
            </span>
            <span v-else-if="header.key === 'tier'">
              <span :class="{ 'font-weight-bold': item.tier === 'PAID' }">{{
                item.tier
              }}</span>
              <v-btn
                size="small"
                :color="
                  item.tier === 'FREE' ? 'grey-lighten-2' : 'green-darken-1'
                "
                @click="updateUserTier(item)"
              >
                Update Tier
              </v-btn>
            </span>
            <span
              v-else-if="
                header.key === 'lastLogin' || header.key === 'lastPayment'
              "
            >
              {{ formatDate(item[header.key]) }}
            </span>
            <span v-else>
              {{ item[header.key] }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-window-item>
</template>

<script lang="ts">
import { onMounted, ref, computed, Ref } from "vue";
import axios from "axios";

interface User {
  id: number;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  lastLogin: string;
  tier: string;
  lastPayment: string;
  billingDate: string;
  roleButton?: string;
  tierButton?: string;
}

export default {
  setup() {
    const toggle: Ref<boolean> = ref(false);
    const searchTerm: Ref<string> = ref("");
    const users: Ref<User[]> = ref([]);

    const headersWithoutId = [
      {
        title: "First Name",
        align: "start",
        key: "firstName",
        value: "firstName",
      },
      {
        title: "Last Name",
        align: "start",
        key: "lastName",
        value: "lastName",
      },
      { title: "Email", align: "start", key: "email", value: "email" },
      { title: "Enabled", align: "start", key: "enabled", value: "enabled" },
      {
        title: "Last Login",
        align: "start",
        key: "lastLogin",
        value: "lastLogin",
      },
      { title: "Tier", align: "start", key: "tier", value: "tier" },
      {
        title: "Last Payment",
        align: "start",
        key: "lastPayment",
        value: "lastPayment",
      },
      {
        title: "Billing Date",
        align: "start",
        key: "billingDate",
        value: "billingDate",
      },
    ] as const;
    const headersWithId = [
      ...headersWithoutId,
      { title: "ID", align: "start", key: "id", value: "id" },
      { title: "Role", align: "start", key: "role", value: "role" },
    ] as const;
    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const toggleId = () => {
      toggle.value = !toggle.value;
    };

    const displayHeaders = computed(() =>
      toggle.value ? headersWithId : headersWithoutId,
    );

    const filteredUsers = computed(() => {
      if (!searchTerm.value) {
        return users.value;
      }
      return users.value.filter(
        (user) =>
          (user.firstName?.toLowerCase() || "").includes(
            searchTerm.value.toLowerCase(),
          ) ||
          (user.lastName?.toLowerCase() || "").includes(
            searchTerm.value.toLowerCase(),
          ),
      );
    });

    const updateUserRole = async (user: User) => {
      try {
        const newRole = user.role === "USER" ? "ADMIN" : "USER";

        const response = await axios.put(`/api/admin/users/${user.id}/role`, {
          role: newRole,
        });
        if (response.status === 200) {
          await getUserList();
        }
      } catch (error) {
        console.error(`Updating role for user ${user.id} failed`, error);
      }
    };

    const updateUserTier = async (user: User) => {
      try {
        const newTier = user.tier === "FREE" ? "PAID" : "FREE";

        const response = await axios.put(`/api/admin/users/${user.id}/tier`, {
          tier: newTier,
        });
        if (response.status === 200) {
          user.tier = newTier;
        }
      } catch (error) {
        console.error(`Updating tier for user ${user.id} failed`, error);
      }
    };

    const getUserList = async () => {
      try {
        const response = await axios.get("/api/admin/users/list");
        // Pre-compute the roleButton and tierButton
        users.value = response.data.map((user: User) => ({
          ...user,
          roleButton: user.role, // Create a roleButton
          tierButton: user.tier, // Create a tierButton
        }));
      } catch (error) {
        console.error("Getting list of users failed", error);
      }
    };

    onMounted(() => {
      getUserList();
    });

    return {
      toggle,
      toggleId,
      users,
      searchTerm,
      filteredUsers,
      displayHeaders,
      updateUserRole,
      updateUserTier,
      formatDate,
    };
  },
};
</script>

<style scoped></style>
