import { createApp } from "vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import axios from "axios";
import { useAuthStore } from "@/store/authStore";
import { useUserStore } from "@/store/userStore";
import "./assets/global.css";
import i18n from "./i18n";

const pinia = createPinia();

// Initialize pinia first
const app = createApp(App).use(pinia);

// Get the userStore and set the language
const userStore = useUserStore();
i18n.global.locale.value = userStore.language as "en" | "ru";

// Continue with the rest of the app initialization
app
  .use(i18n)
  .use(router)
  .use(vuetify)
  .directive("focus", {
    mounted(el: HTMLElement) {
      el.focus();
    },
  })
  .mount("#app");

async function enableFonts() {
  try {
    await loadFonts();
  } catch (error) {
    console.error(error);
  }
}
enableFonts();

async function checkTokenExpiryAndLogout() {
  const store = useAuthStore();
  const now = Date.now();
  const refreshTokenExpiry = Date.parse(store.refreshTokenExpiry || "");

  if (store.refreshTokenExpiry && now > refreshTokenExpiry) {
    store.logout();
    await router.push("/");
  }
}

function checkVisibility() {
  if (document.visibilityState === "visible") {
    checkTokenExpiryAndLogout();
  }
}

document.addEventListener("visibilitychange", checkVisibility);
checkTokenExpiryAndLogout();

function setupInterceptors() {
  const store = useAuthStore();

  //TODO: SA-57 token timeout before request instead of after
  axios.interceptors.request.use(
    (config) => {
      const url = config?.url;
      if (url && !url.includes("/auth/refresh")) {
        const token = store.token;
        if (token) {
          config.headers.Authorization = `Bearer ${store.token}`;
        }
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error) => {
      const originalRequest = error.config;
      if (originalRequest.url.includes("/login")) {
        return Promise.reject(error);
      }
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const newToken = await store.fetchNewToken();

          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          console.error(refreshError);
          store.logout();
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    },
  );
}
checkTokenExpiryAndLogout();
setupInterceptors();
