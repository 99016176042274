import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae59301c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "d-flex flex-column align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "my-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        variant: "elevated",
        style: {"padding":"20px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { justify: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_responsive, {
                    class: "mx-auto mb-10",
                    "max-width": "544"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("resetPasswordView.passwordInstructions")), 1),
                      _createVNode(_component_v_text_field, {
                        modelValue: $setup.password,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.password) = $event)),
                        label: _ctx.$t('resetPasswordView.password'),
                        type: "password"
                      }, null, 8, ["modelValue", "label"]),
                      ($setup.password.length || $setup.confirmPassword.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("div", {
                              class: _normalizeClass({
                  'text-success': $setup.rules.uppercase,
                  'font-weight-bold': $setup.rules.uppercase,
                  'text-uppercase': $setup.rules.uppercase,
                })
                            }, _toDisplayString(_ctx.$t("resetPasswordView.uppercase")), 3),
                            _createElementVNode("div", {
                              class: _normalizeClass({
                  'text-success': $setup.rules.lowercase,
                  'font-weight-bold': $setup.rules.lowercase,
                  'text-uppercase': $setup.rules.lowercase,
                })
                            }, _toDisplayString(_ctx.$t("resetPasswordView.lowercase")), 3),
                            _createElementVNode("div", {
                              class: _normalizeClass({
                  'text-success': $setup.rules.numeric,
                  'font-weight-bold': $setup.rules.numeric,
                  'text-uppercase': $setup.rules.numeric,
                })
                            }, _toDisplayString(_ctx.$t("resetPasswordView.numeric")), 3),
                            _createElementVNode("div", {
                              class: _normalizeClass({
                  'text-success': $setup.rules.length,
                  'font-weight-bold': $setup.rules.length,
                  'text-uppercase': $setup.rules.length,
                })
                            }, _toDisplayString(_ctx.$t("resetPasswordView.length")), 3),
                            _createElementVNode("div", {
                              class: _normalizeClass({
                  'text-success': $setup.rules.match,
                  'font-weight-bold': $setup.rules.match,
                  'text-uppercase': $setup.rules.match,
                })
                            }, _toDisplayString(_ctx.$t("resetPasswordView.passwordMatch")), 3)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_text_field, {
                        modelValue: $setup.confirmPassword,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.confirmPassword) = $event)),
                        label: _ctx.$t('resetPasswordView.confirmPassword'),
                        type: "password",
                        class: "mt-3"
                      }, null, 8, ["modelValue", "label"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.$t("resetPasswordView.confirmNewPassword")), 1),
                    _createVNode(_component_v_btn, {
                      onClick: $setup.resetPassword,
                      class: "mx-auto",
                      style: {"width":"344px"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("resetPasswordView.confirmButton")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}