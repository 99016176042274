<template>
  <v-dialog
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    max-width="400px"
  >
    <v-card class="error-card">
      <v-card-text class="error-text">
        <pre>{{ message }}</pre>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="elevated" class="close-btn" persistent @click="close"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: String,
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const close = () => {
      emit("update:modelValue", false);
    };

    return {
      close,
    };
  },
});
</script>

<style scoped>
.error-card {
  background-color: #efdfe0;
  border-left: 5px solid #ed2a2a;
}

.close-btn {
  margin: 0 auto 16px;
  display: block;
}

.error-text pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  margin: 0;
}
</style>
