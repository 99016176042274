import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import NotFoundView from "@/views/NotFoundView.vue";
import HomeView from "@/views/HomeView.vue";
import RegisterView from "@/views/RegisterView.vue";
import CourseView from "@/views/CourseView.vue";
import CourseListView from "@/views/CourseListView.vue";
import WordsView from "@/views/WordsView.vue";
import SentenceView from "@/views/SentenceView.vue";
import DashboardView from "@/views/DashboardView.vue";
import AccountView from "@/views/AccountView.vue";
import forgotPasswordView from "@/views/ForgotPasswordView.vue";
import resetPasswordView from "@/views/ResetPasswordView.vue";
import termsAndConditionsView from "@/views/TermsAndConditionsView.vue";
import ExerciseListView from "@/views/ExerciseListView.vue";
import RulesView from "@/views/RulesView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/courses",
    name: "courses",
    component: CourseListView,
  },
  {
    path: "/course",
    name: "course",
    component: CourseView,
  },
  {
    path: "/exercises",
    name: "exercise",
    component: ExerciseListView,
  },
  {
    path: "/words",
    name: "words",
    component: WordsView,
  },
  {
    path: "/sentences",
    name: "sentences",
    component: SentenceView,
  },
  {
    path: "/rules",
    name: "rules",
    component: RulesView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: forgotPasswordView,
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: resetPasswordView,
  },
  {
    path: "/terms&conditions",
    name: "termsAndConditions",
    component: termsAndConditionsView,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
