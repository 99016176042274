<template>
  <v-container class="my-container">
    <AlertError v-model="showAlert" :message="alertMessage"></AlertError>
    <v-card variant="elevated" style="padding: 20px">
      <v-responsive class="mx-auto mb-10">
        <v-text-field
          v-model="firstName"
          hide-details="auto"
          :label="$t('registerView.firstName')"
          autofocus
        ></v-text-field>
        <v-text-field
          v-model="lastName"
          hide-details="auto"
          :label="$t('registerView.lastName')"
        ></v-text-field>
        <v-text-field
          v-model="email"
          hide-details="auto"
          :label="$t('registerView.emailLabel')"
          placeholder="johndoe@gmail.com"
          type="email"
        ></v-text-field>
      </v-responsive>
      <v-responsive class="mx-auto">
        <div>
          <p class="mb-3">
            {{ $t("registerView.passwordInstructions") }}
          </p>
          <v-text-field
            v-model="password"
            :label="$t('registerView.password')"
            type="password"
          ></v-text-field>
          <div v-if="password.length || confirmPassword.length">
            <div
              :class="{
                'text-success': rules.uppercase,
                'font-weight-bold': rules.uppercase,
                'text-uppercase': rules.uppercase,
              }"
            >
              {{ $t("registerView.uppercase") }}
            </div>
            <div
              :class="{
                'text-success': rules.lowercase,
                'font-weight-bold': rules.lowercase,
                'text-uppercase': rules.lowercase,
              }"
            >
              {{ $t("registerView.lowercase") }}
            </div>
            <div
              :class="{
                'text-success': rules.numeric,
                'font-weight-bold': rules.numeric,
                'text-uppercase': rules.numeric,
              }"
            >
              {{ $t("registerView.numeric") }}
            </div>
            <div
              :class="{
                'text-success': rules.length,
                'font-weight-bold': rules.length,
                'text-uppercase': rules.length,
              }"
            >
              {{ $t("registerView.length") }}
            </div>
            <div
              :class="{
                'text-success': rules.match,
                'font-weight-bold': rules.match,
                'text-uppercase': rules.match,
              }"
            >
              {{ $t("registerView.passwordMatch") }}
            </div>
          </div>
          <v-text-field
            v-model="confirmPassword"
            :label="$t('registerView.confirmPassword')"
            type="password"
            @keyup.enter="register"
          ></v-text-field>
        </div>
        <div>
          <v-btn
            variant="elevated"
            @click="register"
            class="mx-auto mb-2 font-weight-bold"
            width="100%"
            >{{ $t("registerView.createAccount") }}</v-btn
          >
        </div>
      </v-responsive>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { reactive, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
import AlertError from "@/components/AlertError.vue";

export default {
  components: {
    AlertError,
  },
  setup() {
    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");

    const showAlert = ref(false);
    const alertMessage = ref("");

    const rules = reactive({
      uppercase: false,
      lowercase: false,
      numeric: false,
      length: false,
      match: false,
    });

    watch([password, confirmPassword], () => {
      rules.uppercase = /[A-Z]/.test(password.value);
      rules.lowercase = /[a-z]/.test(password.value);
      rules.numeric = /[0-9]/.test(password.value);
      rules.length = password.value.length >= 8;
      rules.match =
        password.value && confirmPassword.value
          ? password.value === confirmPassword.value
          : false;
    });

    const handleError = (error: unknown) => {
      console.error("Registreerimine ebaõnnestus:", error);
      let errorMessage: string;

      if (axios.isAxiosError(error)) {
        const responseData = error.response?.data;
        if (Array.isArray(responseData?.message)) {
          errorMessage = responseData.message
            .map((msg: string) => `• ${msg}`)
            .join("\n");
        } else if (typeof responseData?.message === "string") {
          errorMessage = responseData.message;
        } else {
          errorMessage =
            error.message || "An error occurred during registration.";
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      } else {
        errorMessage = "An unknown error occurred during registration.";
      }

      alertMessage.value = errorMessage;
      showAlert.value = true;
    };

    const register = async () => {
      if (password.value !== confirmPassword.value) {
        alertMessage.value = "Passwords do not match!";
        showAlert.value = true;
        //Passwords do not match
        return;
      }

      try {
        await axios.post("api/auth/signup", {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          password: password.value,
        });
        router.push("/");
      } catch (error) {
        handleError(error);
        // console.error("Registreerimine ebaõnnestus:", error);
        // if (axios.isAxiosError(error)) {
        //   alertMessage.value =
        //     error.response?.data?.message ||
        //     error.message ||
        //     "An error occurred during registration.";
        // } else if (error instanceof Error) {
        //   alertMessage.value = error.message;
        // } else {
        //   alertMessage.value = "An unknown error occurred during registration.";
        // }
        // showAlert.value = true;
      }
    };
    return {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      register,
      rules,
      showAlert,
      alertMessage,
    };
  },
};
</script>
<style scoped>
.my-container {
  max-width: 550px;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some padding for visual separation */
}
</style>
