import { defineStore } from "pinia";
import axios, { AxiosError } from "axios";
import { useCourseStore } from "@/store/courseStore";
import { RefreshTokenExpiredError } from "@/errors/RefreshTokenExpiredError";
import router from "@/router/index";

/*interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  accessTokenExpiryDate: Date | null;
  refreshTokenExpiryDate: Date | null;
}*/

interface AuthState {
  token: string | null;
  refreshToken: string | null;
  refreshTokenExpiry: string | null;
  isRefreshingToken: boolean;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    token: localStorage.getItem("token") || null, //Retrieve token from localStorage
    refreshToken: localStorage.getItem("refreshToken") || null,
    refreshTokenExpiry: localStorage.getItem("refreshTokenExpiry") || null,
    isRefreshingToken: false,
  }),
  getters: {
    isAuthenticated: (state) => {
      const hasTokens =
        !!state.token && !!state.refreshToken && !!state.refreshTokenExpiry;

      if (hasTokens) {
        // we need to use state.refreshTokenExpiry instead of refreshTokenExpiry
        return new Date() < new Date(state.refreshTokenExpiry ?? "");
      }
      return false;
    },
  },
  actions: {
    setToken(
      token: string | null,
      refreshToken: string | null,
      refreshTokenExpiry: string | null,
    ) {
      this.token = token;
      this.refreshToken = refreshToken;
      this.refreshTokenExpiry = refreshTokenExpiry;

      if (token)
        localStorage.setItem("token", token); // Save token to localStorage
      else localStorage.removeItem("token"); //Remove token from localStorage

      if (refreshToken) localStorage.setItem("refreshToken", refreshToken);
      else localStorage.removeItem("refreshToken");

      if (refreshTokenExpiry)
        localStorage.setItem("refreshTokenExpiry", refreshTokenExpiry);
      else localStorage.removeItem("refreshTokenExpiry");
    },

    async fetchNewToken() {
      if (this.isRefreshingToken) {
        return;
      }
      this.isRefreshingToken = true;

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${this.refreshToken}`,
          },
        };
        const response = await axios.get("api/auth/refresh", config);

        if (response.status === 401) {
          this.logout();
          router.push("/");
          throw new RefreshTokenExpiredError();
        }
        const newToken = response.data.accessToken;
        const newRefreshToken = response.data.refreshToken;
        const newRefreshTokenExpiry = response.data.refreshTokenExpiryDate;

        this.setToken(newToken, newRefreshToken, newRefreshTokenExpiry);

        return newToken;
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error(error);

        if (axiosError.response && axiosError.response.status === 401) {
          console.error(error);
          this.logout();
          router.push("/");
        }
        throw axiosError;
      } finally {
        this.isRefreshingToken = false;
      }
    },
    logout() {
      const courseStore = useCourseStore();
      courseStore.removeCourse();
      courseStore.removeTopic();
      // Clear the token or perform any other logout-related actions
      this.token = null;
      this.refreshToken = null;
      this.refreshTokenExpiry = null;

      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("refreshTokenExpiry");

      this.$reset();
    },
  },
});
