<template>
  <v-container class="my-container">
    <v-card variant="elevated" style="padding: 20px">
      <v-row justify="center">
        <v-col>
          <v-responsive class="mx-auto mb-10" max-width="344">
            <v-text-field
              v-model="email"
              hide-details="auto"
              :label="$t('forgotPasswordView.emailLabel')"
              placeholder="johndoe@gmail.com"
              type="email"
            ></v-text-field>
          </v-responsive>

          <div class="d-flex flex-column align-center">
            <b>{{ $t("forgotPasswordView.sendResetLinkText") }}</b>
            <v-btn
              @click="forgotPassword"
              class="mx-auto"
              style="width: 344px"
              >{{ $t("forgotPasswordView.sendButtonText") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { ref } from "vue";
import axios from "axios";

export default {
  setup() {
    const email = ref("");

    const forgotPassword = async () => {
      try {
        await axios.post("/api/auth/forgot-password", {
          email: email.value,
        });
      } catch (error) {
        console.error("Salasõna lähtestamine ebaõnnestus", error);
      }
    };

    return { email, forgotPassword };
  },
};
</script>

<style scoped>
.my-container {
  max-width: 550px;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some padding for visual separation */
}
</style>
