import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_DashboardUsers = _resolveComponent("DashboardUsers")!
  const _component_DashboardCourses = _resolveComponent("DashboardCourses")!
  const _component_DashboardStats = _resolveComponent("DashboardStats")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_tabs, {
      modelValue: $setup.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.activeTab) = $event)),
      "align-tabs": "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, { value: "kasutajad" }, {
          default: _withCtx(() => [
            _createTextVNode("Kasutajad")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "materjalid" }, {
          default: _withCtx(() => [
            _createTextVNode("Materjalid")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "statistika" }, {
          default: _withCtx(() => [
            _createTextVNode("Statistika")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_window, {
      modelValue: $setup.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.activeTab) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DashboardUsers),
        _createVNode(_component_DashboardCourses),
        _createVNode(_component_DashboardStats)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}