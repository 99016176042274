import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af1332ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError = _resolveComponent("AlertError")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "my-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_AlertError, {
        modelValue: $setup.showAlert,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showAlert) = $event)),
        message: $setup.alertMessage
      }, null, 8, ["modelValue", "message"]),
      _createVNode(_component_v_card, {
        variant: "elevated",
        style: {"padding":"20px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_responsive, { class: "mx-auto mb-10" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: $setup.firstName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.firstName) = $event)),
                "hide-details": "auto",
                label: _ctx.$t('registerView.firstName'),
                autofocus: ""
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: $setup.lastName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.lastName) = $event)),
                "hide-details": "auto",
                label: _ctx.$t('registerView.lastName')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_v_text_field, {
                modelValue: $setup.email,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.email) = $event)),
                "hide-details": "auto",
                label: _ctx.$t('registerView.emailLabel'),
                placeholder: "johndoe@gmail.com",
                type: "email"
              }, null, 8, ["modelValue", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_responsive, { class: "mx-auto" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("registerView.passwordInstructions")), 1),
                _createVNode(_component_v_text_field, {
                  modelValue: $setup.password,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.password) = $event)),
                  label: _ctx.$t('registerView.password'),
                  type: "password"
                }, null, 8, ["modelValue", "label"]),
                ($setup.password.length || $setup.confirmPassword.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", {
                        class: _normalizeClass({
                'text-success': $setup.rules.uppercase,
                'font-weight-bold': $setup.rules.uppercase,
                'text-uppercase': $setup.rules.uppercase,
              })
                      }, _toDisplayString(_ctx.$t("registerView.uppercase")), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                'text-success': $setup.rules.lowercase,
                'font-weight-bold': $setup.rules.lowercase,
                'text-uppercase': $setup.rules.lowercase,
              })
                      }, _toDisplayString(_ctx.$t("registerView.lowercase")), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                'text-success': $setup.rules.numeric,
                'font-weight-bold': $setup.rules.numeric,
                'text-uppercase': $setup.rules.numeric,
              })
                      }, _toDisplayString(_ctx.$t("registerView.numeric")), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                'text-success': $setup.rules.length,
                'font-weight-bold': $setup.rules.length,
                'text-uppercase': $setup.rules.length,
              })
                      }, _toDisplayString(_ctx.$t("registerView.length")), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass({
                'text-success': $setup.rules.match,
                'font-weight-bold': $setup.rules.match,
                'text-uppercase': $setup.rules.match,
              })
                      }, _toDisplayString(_ctx.$t("registerView.passwordMatch")), 3)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_text_field, {
                  modelValue: $setup.confirmPassword,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.confirmPassword) = $event)),
                  label: _ctx.$t('registerView.confirmPassword'),
                  type: "password",
                  onKeyup: _withKeys($setup.register, ["enter"])
                }, null, 8, ["modelValue", "label", "onKeyup"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_v_btn, {
                  variant: "elevated",
                  onClick: $setup.register,
                  class: "mx-auto mb-2 font-weight-bold",
                  width: "100%"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("registerView.createAccount")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}