<template>
  <div class="language-container">
    <v-select
      v-model="selectedLanguage"
      :items="languages"
      item-value="value"
      item-title="title"
      @change="changeLanguage"
      class="language-selector"
      density="compact"
    ></v-select>
    <div v-if="selectedFlag" class="flag-container">
      <img :src="selectedFlag" class="flag-image" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/store/userStore";
import { ref, onMounted, watch } from "vue";

// Import flags
import flagEnSrc from "@/assets/flags/gb.png";
import flagRuSrc from "@/assets/flags/ru.png";

const { locale } = useI18n();
const userStore = useUserStore();
const selectedLanguage = ref(userStore.language);
const languages = [
  { value: "en", title: "En" },
  { value: "ru", title: "Ru" },
];

const selectedFlag = ref(flagEnSrc);

// Function to get flag URL based on language value
const getFlagURL = (value: string) => {
  switch (value) {
    case "en":
      return flagEnSrc;
    case "ru":
      return flagRuSrc;
    default:
      return "";
  }
};

onMounted(() => {
  locale.value = selectedLanguage.value;
  selectedFlag.value = getFlagURL(selectedLanguage.value);
});

// Watch for changes to selectedLanguage
watch(selectedLanguage, (newLanguage) => {
  console.log("Watcher Triggered: Language to change is", newLanguage);
  locale.value = newLanguage;
  userStore.setLanguage(newLanguage);
  selectedFlag.value = getFlagURL(newLanguage);
});

const changeLanguage = (value: string) => {
  console.log("Changing Language to:", value);
  selectedLanguage.value = value;
};
</script>

<style scoped>
.language-container {
  display: flex;
  align-items: center;
}

.language-selector {
  width: 82px;
  height: 40px;
}

.flag-container {
  margin-left: 4px; /* Adjust according to your layout */
  display: flex;
}

.flag-image {
  height: 32px;
}
</style>
